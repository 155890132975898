import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";

// const url = "http://findmycones.com:46508";

function Login(props) {
  const [validEmail, setValidEmail] = useState(true);
  const [doingLogin, setDoingLogin] = useState(false);
  const [blocked, setBlocked] = useState(false);
  const [incorrectEmail, setIncorrectEmail] = useState(false);
  const [incorrectPassword, setIncorrectPassword] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [noEmail, setNoEmail] = useState(false);
  const [noPassword, setNoPassword] = useState(false);
  const [needsVerification, setNeedsVerification] = useState(false);

  const validateEmail = (e) => {
    setNoEmail(false);
    let regex = new RegExp("[a-z0-9]+@[a-z]+.[a-z]{2,3}");

    if (regex.test(e.target.value)) {
      setValidEmail(true);
    } else {
      setValidEmail(false);
    }
    setEmail(e.target.value);
  };

  const handlePassword = (e) => {
    setPassword(e.target.value);
    setNoPassword(false);
  };

  const handleLogin = () => {
    setBlocked(false);
    setNeedsVerification(false);
    setIncorrectEmail(false);
    setIncorrectPassword(false);

    if (email === "") {
      setNoEmail(true);
    }

    if (password === "") {
      setNoPassword(true);
    }

    if (email && password) {
      setDoingLogin(true);

      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("x-api-key", "fa57386dc4f772b69a750f06cb408");
      var raw = JSON.stringify({
        email: email,
        password: password,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch("https://findmycones.com:46508/api/login", requestOptions)
        .then((result) => {
          if (result.status === 404) {
            // email not found
            setDoingLogin(false);
            setIncorrectEmail(true);
          } else if (result.status === 403) {
            // blocked from joomla
            setDoingLogin(false);
            setBlocked(true);
          } else if (result.status === 406) {
            // blocked from joomla
            setDoingLogin(false);
            setNeedsVerification(true);
          } else if (result.status === 401) {
            // incorrect password
            setDoingLogin(false);
            setIncorrectPassword(true);
          } else if (result.status === 200) {
            // ok!
            localStorage.clear();

            result.json().then((body) => {
              localStorage.setItem("user", JSON.stringify(body));
              props.setUser(body);
            });

            // localStorage.setItem("user", result.body.user);
            // props.setUser(result.body.user);
            props.setShowLogin(false);

            setTimeout(() => {
              setDoingLogin(false);
              props.getMapData();
            }, 500);
          }
        })
        .catch((error) => {
          console.log(error);
          setDoingLogin(false);
        });
      setDoingLogin(false);
    }
  };

  return (
    <>
      <Dialog open={props.open} sx={{ "& .MuiDialog-paperWidthSm": { padding: "20px", margin: "0px", width: "80%" } }}>
        <DialogTitle>Login</DialogTitle>
        <Stack spacing={2}>
          <TextField
            onChange={validateEmail}
            id="emailField"
            type="email"
            label="Email"
            variant="outlined"
            required
            error={!validEmail || noEmail}
            helperText={incorrectEmail ? "Email is incorrect" : ""}
          />
          <TextField
            onChange={handlePassword}
            id="passwordField"
            type="password"
            label="Password"
            variant="outlined"
            error={noPassword}
            required
            helperText={incorrectPassword ? "Password is incorrect" : ""}
          />
          <a style={{textDecoration: "none", color: "#1976d2"}} href="https://findmycones.com/log-in?view=reset" target="_blank"  rel="noopener noreferrer">Forgot Password?</a>
          <Button
            onClick={blocked ? null : handleLogin}
            color={blocked ? "error" : needsVerification ? "warning" : "primary"}
            variant="contained"
            sx={{ height: "36.5px" }}
          >
            {doingLogin ? (
              <CircularProgress size="26px" sx={{ "& .MuiCircularProgress-circle": { color: "white" } }} />
            ) : blocked ? (
              "Your account is blocked"
            ) : needsVerification ? (
              "See Email to verify account"
            ) : (
              "Login"
            )}
          </Button>
        </Stack>
        <div style={{ textAlign: "center", margin: "40px" }}>
          Don`t have an account?&nbsp;&nbsp;
          <a style={{textDecoration: "none", color:"#1976d2"}} href="https://findmycones.com/component/users/registration" target="_blank"  rel="noopener noreferrer">Sign up</a>
        </div>
      </Dialog>
    </>
  );
}

export default Login;
