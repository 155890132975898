import React, { useState } from "react";
import Box from "@mui/material/Box";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Camera, { FACING_MODES, IMAGE_TYPES } from "react-html5-camera-photo";
import Loader from "./Loader";

import "react-html5-camera-photo/build/css/index.css";

function Capture(props) {
  const { coords, user, setSection, getMapData, description, coneAmount, setDescription, setConeAmount, killTimer, startTimer } = props;
  const handleClose = () => setOpen(false);
  const [dataUri, setDataUri] = useState("");
  const [open, setOpen] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [saveMessage, setSaveMessage] = useState("Saving");
  const [coneError, setConeError] = useState(false);
  const captureSectionWrapper = document.querySelector(".captureSectionWrapper");

  const handleOpen = (props) => {
    killTimer();
    setOpen(true);
    captureSectionWrapper.style.backgroundColor = "#00000055";
  };

  const handleShowMap = () => {
    startTimer();
    setSection(1);
  };

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };

  const handleConeChange = (e) => {
    let targetInt = parseInt(e.target.value);
    setConeAmount(targetInt);
    if (typeof targetInt !== "number" || Number.isNaN(targetInt) || targetInt === null) {
      setConeError(true);
    } else {
      setConeError(false);
    }
  };

  const handleTakePhoto = (data) => {
    setDataUri(data);
    setOpen(false);
    captureSectionWrapper.style.backgroundColor = "transparent";
  };

  const handleSave = () => {
    if (dataUri === "") {
      alert("You must take a picture before you can save!");
    } else if (description === "") {
      alert("You must enter a decsription");
    } else if (coneError || coneAmount === "") {
      alert("You must enter a cone amount");
    } else {
      setShowLoader(true);
      let dataCode = (Math.random() + 1).toString(36).substring(2);

      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("x-api-key", "fa57386dc4f772b69a750f06cb408");
      
      var raw = JSON.stringify({
        lat: `${coords.lat}`,
        lng: `${coords.lng}`,
        dataUri: dataUri,
        dataCode: `${dataCode}`,
        description: `${description === "" ? "None" : description}`,
        status: 1,
        owner_id: user.userId,
        org_id: `${user.orgId !== 0 ? user.orgId : 0}`,
        cones_left: coneAmount,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      // const errorBreakOut = (json) => {
      //   setShowLoader(false);
      //   doFinalizeSave();
      //   alert(json.error);
      //   // window.location.href = "https://findmycones.com/prices";
      //   return Promise.reject(json.error);
      // };

      const doFinalizeSave = () => {
        setDataUri("");
        handleClose();
        setTimeout(() => {
          getMapData();
          setSection(1);
        }, 500);
      };

      fetch("https://findmycones.com:46508/api/submit", requestOptions)
        .then((response) => response.json())
        .then((json) => {
          if (Object.values(json).some(value => value === 'nosub')){
              window.location.href = "https://findmycones.com/prices";
              throw new Error('Max Cone Limit Reached')
          }
          return json;
        })
        .then(() => {
          setSaveMessage("Saved!");
          setTimeout(() => {
            setShowLoader(false);
          }, 2000);
          doFinalizeSave();
        })
        .catch((error) => {
        
          setSaveMessage(`An Error Ocurred: ${error}`);
          setTimeout(() => {
            setShowLoader(false);
          }, 10000);
          doFinalizeSave();
          console.log("error", error)
        });
    }
  };

  return (
    <div className="captureSectionWrapper">
      <Loader open={showLoader} message={saveMessage} />
      {open === true ? (
        <Camera
          sizeFactor={0.3}
          imageCompression={1}
          imageType={IMAGE_TYPES.JPG}
          idealResolution={{ width: 500, height: 375 }}
          onTakePhoto={(dataUri) => {
            handleTakePhoto(dataUri);
          }}
          idealFacingMode={FACING_MODES.ENVIRONMENT}
          isFullscreen={false}
        />
      ) : null}
      <Box
        onClick={handleOpen}
        sx={{
          width: "75vw",
          height: "25%",
          border: dataUri ? "1px solid grey" : "2px dashed grey",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          overflow: "hidden",
          textAlign: "center",
        }}
      >
        {dataUri ? (
          <img
            className="imagePreview"
            alt=""
            style={{ width: "75vw", height: "75vw", objectFit: "fill" }}
            src={dataUri}
          />
        ) : (
          <div>
            <CameraAltIcon />
            <div>Capture Photo</div>
          </div>
        )}
      </Box>
      <Stack spacing={1}>
        <TextField
          id="capturedLat"
          sx={{ "& .MuiInputBase": { width: "75vw" } }}
          value={coords.lat}
          label="Latitude"
          variant="outlined"
          style={{ width: "75vw" }}
        />
        <TextField
          id="capturedLng"
          sx={{ "& .MuiInputBase": { width: "75vw" } }}
          value={coords.lng}
          label="Longitude"
          variant="outlined"
        />
        <TextField
          style={{ marginTop: "20px" }}
          sx={{ "& .MuiInputBase": { width: "75vw" } }}
          id="description"
          label="Description"
          variant="outlined"
          value={description}
          onChange={handleDescriptionChange}
        />
        <TextField
          onChange={handleConeChange}
          label="Number of cones left onsite"
          inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
          error={coneError}
          value={coneAmount}
        />
      </Stack>
      <Stack direction="row" spacing={2}>
        <Button variant="contained" onClick={handleSave}>
          Save
        </Button>
        <Button variant="contained" onClick={handleShowMap}>
          Cancel
        </Button>
      </Stack>
    </div>
  );
}

export default Capture;
