import React from "react";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Dialog from "@mui/material/Dialog";
import Button from "@mui/material/Button";
import Divider from '@mui/material/Divider';
import { List } from "@mui/material";

function Account(props) {
  const {user, open, handleShowAccount, setUser, setSection, showAccount } = props;

  const handleLogout = () => {
    setUser({ id: 0 });
    localStorage.clear();
    setSection(1);
    handleShowAccount();
  };

  const handleClose = () => {
    showAccount(false);
  };

  const handleDeleteAccount = () => {
    window.open("https://findmycones.com/account/delete-account", "_blank");
  }

  return (
    <Dialog onClose={handleClose} open={open}>
      <List component="div" role="group">
        <div style={{fontSize: '12px', position: 'absolute', right: '11px', color: '#bbb6b6'}}>build 2.0.0</div>
        <ListItem>
          <ListItemText primary="Account" />
        </ListItem>
        <Divider />
        <ListItem>
          <ListItemText primary={user.name} secondary={user.orgName ? user.orgName : 'No Organization'} />
        </ListItem>
      </List>
      <Button onClick={handleLogout} variant="text" color="primary">
        Logout
      </Button>
      <Button onClick={handleDeleteAccount} variant="contained" color="error">
        Delete Account
      </Button>
    </Dialog>
  );
}

export default Account;
