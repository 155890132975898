import React, { useState, useEffect } from "react";
import AppBar from "./AppBar";
import Map from "./Map";
import Avatar from "@mui/material/Avatar";
import Capture from "./Capture";
import Login from "./Login";
import Account from "./Account";
import "./App.css";

function App() {
  const [user, setUser] = useState({ id: 0 });
  const [currentSection, setSection] = useState(1);
  const [showLogin, setShowLogin] = useState(false);
  const [showAccount, setShowAccount] = useState(false);
  const [coords, setCoords] = useState({ lat: 44.967243, lng: -103.771556 });
  const [locationLocked, setLocationLocked] = useState(true);
  const [mapData, setMapData] = useState([]);
  const [coneCount, setConeCount] = useState(0);
  const [intervalId, setIntervalId] = useState(0);
  const [timeLock, setTimeLock] = useState("");
  const [coneAmount, setConeAmount] = useState("");
  const [description, setDescription] = useState("");

  const setLocation = (type) => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        setCoords({ lat: position.coords.latitude, lng: position.coords.longitude });
      });
    }
  };
  
  useEffect(() => {
    setLocation();
    if (localStorage.getItem("user")) {
      console.log(JSON.parse(localStorage.getItem("user")))
      setUser(JSON.parse(localStorage.getItem("user")));
    }
  }, []);

  const killTimer = () => {
    if (intervalId) {
      clearInterval(intervalId);
      setIntervalId(0);
      setTimeLock("stopped");
      return;
    }
  };
  const startTimer = () => {
    if (timeLock !== "started") {
      const newIntervalId = setInterval(() => {
        setLocation(2);
      }, 1000);
      setIntervalId(newIntervalId);
      setTimeLock("started");
    }
  };

  const getMapData = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("x-api-key", "fa57386dc4f772b69a750f06cb408");

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    let user = localStorage.getItem("user");
    if (user === null) return;
    fetch(`https://findmycones.com:46508/api/request?user=${user}`, requestOptions)
      .then((response) => response.json())
      .then((data) => setMapData(data))
      .catch((error) => console.log("error", error));
  };

  function handleLogin() {
    if (!showLogin) {
      setShowLogin(!showLogin);
    }
  }

  const handleShowAccount = () => {
    setShowAccount(!showAccount);
  };

  function stringToColor(string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
      const aValue = (hash >> (i * 8)) & 0xff;
      color += `00${aValue.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  }

  function stringAvatar(user) {
    const name = `${user.name.split(" ")[0]} ${user.name.split(" ")[1]}`;
    return {
      sx: {
        bgcolor: stringToColor(name),
      },
      children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
    };
  }

  return (
    <div className="App">
      <Login setUser={setUser} setShowLogin={setShowLogin} open={showLogin} getMapData={getMapData} />
      <Account
        open={showAccount}
        showAccount={setShowAccount}
        user={user}
        setUser={setUser}
        handleShowAccount={handleShowAccount}
        setSection={setSection}
        setConeCount={setConeCount}
      />
      <header className="App-header">
        <AppBar
          user={
            user !== null && user.id !== 0 ? (
              <Avatar {...stringAvatar(user)} onClick={handleShowAccount} />
            ) : (
              <div onClick={handleLogin}>Login</div>
            )
          }
        />
      </header>
      <div className="sectionWrapper">
        <section
          className="section"
          id="scr2"
          style={currentSection === 1 ? { display: "block" } : { display: "none" }}
        >
          <Map
            coneCount={coneCount}
            user={user}
            coords={coords}
            mapData={mapData}
            getMapData={getMapData}
            setLocation={setLocation}
            locationLocked={locationLocked}
            setLocationLocked={setLocationLocked}
            setSection={setSection}
            description={description}
            coneAmount={coneAmount}
            setDescription={setDescription}
            setConeAmount={setConeAmount}
            killTimer={killTimer}
            startTimer={startTimer}
          />
        </section>
        <section
          className="section"
          id="scr1"
          style={currentSection === 2 ? { display: "block" } : { display: "none" }}
        >
          <Capture
            user={user}
            coords={coords}
            setSection={setSection}
            getMapData={getMapData}
            coneCount={coneCount}
            description={description}
            setDescription={setDescription}
            coneAmount={coneAmount}
            setConeAmount={setConeAmount}
            killTimer={killTimer}
            startTimer={startTimer}
          />
        </section>
      </div>
    </div>
  );
}

export default App;
