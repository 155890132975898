import React, {} from "react";
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Dialog from "@mui/material/Dialog";


export default function Loader(props) {

  return (
    <Dialog onClose={props.handleClose} open={props.open}>

    <Box sx={{ display: 'flex',flexDirection: 'column', justifyContent: 'center', alignItems:'center', padding: '20px 40px', overflow: 'hidden'}}>
      <CircularProgress />
      <div style={{marginTop:'20px'}}>{props.message}</div>
    </Box>
    </Dialog>
  );
}