import React, { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Loader from "./Loader";
import "./App.css";

function MapDialog(props) {
  const {
    openDialog,
    readyForPickup,
    dataUri,
    dataCode,
    handleCloseDialog,
    adjustedMarker,
    getMapData,
    description,
    markerCoords,
    initialCones,
    created
  } = props;


  const [coneAmount, setConeAmount] = useState(null);
  const [showLoader, setShowLoader] = useState(false);
  const [saveMessage, setSaveMessage] = useState("Saving");
  const [coneError, setConeError] = useState(false);
  const [coords, setCoords] = useState({});

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        setCoords({ lat: position.coords.latitude, lng: position.coords.longitude });
      });
    }
  }, []);

  const handleConeChange = (e) => {
    let targetInt = parseInt(e.target.value);
    setConeAmount(targetInt);
    if (typeof targetInt !== "number" || Number.isNaN(targetInt) || targetInt === null) {
      setConeError(true);
    } else {
      setConeError(false);
    }
  };

  const handleCloseLocation = () => {
    if (!coneError) {
      setShowLoader(true);
      var myHeaders = new Headers();
      myHeaders.append("x-api-key", "fa57386dc4f772b69a750f06cb408");
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        conesRemaining: coneAmount,
        id: adjustedMarker,
      });

      var requestOptions = {
        method: "PUT",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch("https://findmycones.com:46508/api/update/location", requestOptions)
        .then((response) => response.text())
        .catch((error) => console.log("error", error))
        .finally(() => {
          setSaveMessage("Saved!");
          setTimeout(() => {
            setShowLoader(false);
          }, 2000);
          getMapData();
          handleCloseDialog();
        });
    }
  };
  return (
    <Dialog sfx={{height: "auto"}} onClose={handleCloseDialog} open={openDialog}>
      <Loader open={showLoader} message={saveMessage} />
      <Stack spacing={2}>
        <div
          style={{
            padding: "20px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}>
          {readyForPickup ? null : (
            <div style={{ marginBottom: "20px" }}>
              <strong>This location is not ready for pickup</strong>
            </div>
          )}
        <h3>{created}</h3>

          <img src={dataUri} alt={dataCode} className='dialogImage' />
          <Box label='Description' sx={{ margin: "20px 0px 0px 0px" }}>
            <div>Description: {description}</div>
          </Box>

        <a className="testStyle" href={`https://www.google.com/maps/dir/${coords.lat},${coords.lng}/${markerCoords.lat},${markerCoords.lng}`}>Directions</a>
          {/* <a href="//maps.apple.com/?q=Raleigh,NC">maps</a> */}
          <div>Cones left on site: {`${initialCones}`}</div>

          <TextField
            sx={{ marginBottom: "20px", marginTop: "20px" }}
            onChange={handleConeChange}
            label='Cones picked up'
            disabled={!readyForPickup}
            inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
            required
            error={coneError}
          />
          <Button
            color={"error"}
            sx={{ marginBottom: "20px" }}
            disabled={!readyForPickup}
            onClick={handleCloseLocation}
            variant='contained'>
            Close Location
          </Button>
          <Button variant='contained' onClick={handleCloseDialog}>
            Exit
          </Button>
        </div>
      </Stack>
    </Dialog>
  );
}

export default MapDialog;
