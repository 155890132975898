import React, { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import { DirectionsService } from "@react-google-maps/api";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";

const DirectionDialog = (props) => {
  const { open, setOpenDirections } = props;
  const [coords, setCoords] = useState({});
  const [response, setResponse] = useState(null);
  const [flag, setFlag] = useState(true);

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        setCoords({ lat: position.coords.latitude, lng: position.coords.longitude });
      });
    }
  }, []);

  const handleCloseDialog = () => {
    setOpenDirections(false);
  };

  const directionsCallback = (obj, status) => {
    if (obj.status !== null) {
      if (obj.status === "OK") {
        if (flag) {
          setResponse(obj.routes[0].legs[0].steps);
          setFlag(false);
        }
      }
    }
  };

  const DirectionsServiceOption = {
    destination: { lat: 42, lng: -86 },
    origin: coords,
    travelMode: "DRIVING",
  };

  const createMarkup = (markup) => {
    return { __html: `${markup}` };
  };

  return (
    <Dialog
      open={open}
      onClose={handleCloseDialog}
      sx={{ lineHeight: "2", "& .MuiPaper-root": { padding: "20px", margin: "0px" } }}
    >
      <DirectionsService
        options={DirectionsServiceOption}
        callback={(obj, status) => directionsCallback(obj, status)}
      />
      <Grid container direction="column" sx={{ height: "90vh" }}>
        <Grid item sx={{ maxHeight: "80vh", overflow: "scroll", marginBottom: "20px" }}>
          {response !== null
            ? response.map((res) => (
                <div key={Math.random()} dangerouslySetInnerHTML={createMarkup(res.instructions)} />
              ))
            : null}
        </Grid>
        <Grid item sx={{ height: "40px" }}>
          <Button onClick={handleCloseDialog} variant="contained" sx={{ width: "100%" }}>
            Close
          </Button>
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default DirectionDialog;
