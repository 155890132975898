import React, { useState, useEffect } from "react";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import MapIconGroup from "./MapIconGroup";
import MapDialog from "./MapDialog";
import DirectionDialog from "./DirectionDialog";
import MarkerGroup from "./MarkerGroup";

var moment = require("moment");

const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

let canceled = false;

function Map(props) {
  const { coneCount, setSection, coords, locationLocked, setLocationLocked, user, mapData, getMapData, setDescription, setConeAmount, description, coneAmount, startTimer, killTimer } = props;
  const containerStyle = {
    width: "100vw",
    height: "100%",
  };
  const mapOptions = {
    fullscreenControl: false,
    zoomControl: true,
    mapTypeControl: false,
    scaleControl: false,
    streetViewControl: false,
    rotateControl: false,
    keyboardShortcuts: false,
    zoomControlOptions: { position: 6 },
  };
  const [mapInstance, setMapInstance] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [dataCode, setDataCode] = useState("");
  const [dataUri, setDataUri] = useState("");
  const [adjustedMarker, setAdjustedMarker] = useState(0);
  const [readyForPickup, setReadyForPickup] = useState(false);
  const [dragLocked, setDragLocked] = useState(true);
  const [openDirections, setOpenDirections] = useState(false);
  const [markerCoords, setMarkerCoords] = useState({});
  const [creationDate, setCreationDate] = useState("");


  useEffect(() => {
    if (!canceled) {
      canceled = true;
      getMapData();
    }
  }, [getMapData]);

  const handleShowDialog = (obj) => {
    if (moment().isAfter(obj.created, "day")) {
      setReadyForPickup(true);
    } else {
      setReadyForPickup(false);
    }
    setDataCode(obj.dataCode);
    setDataUri(obj.dataUri);
    setAdjustedMarker(obj.id);
    setDescription(obj.description);
    setMarkerCoords({ lat: obj.lat, lng: obj.lng });
    setConeAmount(obj.cones_left);
    setOpenDialog(true);
    setCreationDate(obj.created);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const onLoad = (mapInstance) => {
    setMapInstance(mapInstance);
    startTimer();
  };

  const handlePanToHome = () => {
    mapInstance.panTo(coords);
    setLocationLocked(true);
    startTimer();
  };

  const handleSearching = () => {
    if (locationLocked) {
      killTimer();
    }
    setLocationLocked(false);
  };

  const handleDragLock = () => {
    if (user.id !== 0) {
      setDragLocked(!dragLocked);
      killTimer();
    }
  };

  return (
    <>
      <LoadScript googleMapsApiKey={apiKey}>
        <GoogleMap
          clickableIcons={false}
          mapContainerStyle={containerStyle}
          center={coords}
          zoom={12}
          options={mapOptions}
          onDragStart={handleSearching}
          onLoad={onLoad}
        >
          <MarkerGroup mapData={mapData} user={user} handleShowDialog={handleShowDialog} />

          <MapIconGroup
            coneCount={coneCount}
            locationLocked={locationLocked}
            handlePanToHome={handlePanToHome}
            setSection={setSection}
            dragLocked={dragLocked}
            handleDragLock={handleDragLock}
            user={user}
            setConeAmount={setConeAmount}
            setDescription={setDescription}
          />
          <Marker position={coords} opacity={1} zIndex={999} />
        </GoogleMap>
      </LoadScript>

      <DirectionDialog open={openDirections} setOpenDirections={setOpenDirections} />
      <MapDialog
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
        readyForPickup={readyForPickup}
        dataUri={dataUri}
        dataCode={dataCode}
        handleCloseDialog={handleCloseDialog}
        adjustedMarker={adjustedMarker}
        getMapData={getMapData}
        description={description}
        setOpenDirections={setOpenDirections}
        markerCoords={markerCoords}
        initialCones={coneAmount}
        created={moment(creationDate).format("MMMM Do YYYY")}
      />
    </>
  );
}

export default Map;
