// src/components/MarkerGroup.js

import React, { useEffect } from "react";
import { Marker } from "@react-google-maps/api";
import moment from "moment";
import greenMarker from "./greencone_small.png";
import redMarker from "./redcone_small.png";

const MarkerGroup = ({ mapData, getMapData, user, handleShowDialog }) => {
  useEffect(() => {
    // Implement the function that fetches map data if needed
    // ... your implementation here ...

    //console.log(mapData, user)
    return () => {
      // Handle cleanup here if needed
    };
  }, []);

  return (
    <>
      {user !== null ? (
        mapData.map((data) =>
          data.org_id === user.orgId ? (
            <Marker
              label={{ text: `${data.cones_left}`, className: "marker-label" }}
              icon={moment().isAfter(data.created, "day") ? greenMarker : redMarker}
              key={data.id}
              position={{ lat: data.lat, lng: data.lng }}
              onClick={() => handleShowDialog(data)}
            />
          ) : null
        )
      ) : (
        <div>Please Log In</div>
      )}
    </>
  );
};

export default MarkerGroup;
