import React from "react";
// import LockIcon from "@mui/icons-material/Lock";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
// import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import Card from "@mui/material/Card";
import MyLocationIcon from "@mui/icons-material/MyLocation";
import LocationSearchingIcon from "@mui/icons-material/LocationSearching";
import ContactSupportIcon from "@mui/icons-material/ContactSupport";

function MapIconGroup(props) {
  // handleDragLock, dragLocked
  const { locationLocked, handlePanToHome, user, setSection, setConeAmount, setDescription } = props;

  const handleTicket = () => {
    window.open("https://findmycones.com/ticket");
  };

  return (
    <Card
      sx={{
        top: "40%",
        height: "15rem",
        right: "0px",
        position: "absolute",
        zIndex: "999",
        backgroundColor: "#ffffffaa",
        width: "4rem",
        margin: "0 auto",
        padding: "0px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-around",
      }}
    >
      <ContactSupportIcon onClick={handleTicket} color={"primary"} sx={{ fontSize: "3rem" }} />

      <div onClick={handlePanToHome}>
        {locationLocked ? (
          <MyLocationIcon color={"primary"} sx={{ fontSize: "3rem" }} />
        ) : (
          <LocationSearchingIcon color={"primary"} sx={{ fontSize: "3rem" }} />
        )}
      </div>
      <CameraAltIcon
        sx={{ fontSize: "3rem" }}
        color={"primary"}
        onClick={() => {
          if (user.id !== 0) {
            setConeAmount("");
            setDescription("");
            setSection(2);
          } else {
            alert("Please login to view and capture job markers");
          }
        }}
      />
      {/* {dragLocked ? (
        <LockIcon sx={{ fontSize: "3rem" }} onClick={handleDragLock} color={"primary"} />
      ) : (
        <LockOpenOutlinedIcon sx={{ fontSize: "3rem" }} onClick={handleDragLock} color={"action"} />
      )} */}
    </Card>
  );
}

export default MapIconGroup;
